import React from 'react'

const Social = () => {
  return (
    <div className='home__social' data-aos="fade-right" data-aos-duration="2000">
        <a href='https://fr.linkedin.com/in/beverly-diaz' className='home__social-icon' target='_blank' rel="noreferrer"><i class="uil uil-linkedin"></i></a>
        <a href='https://github.com/BeverlyDiaz' className='home__social-icon' target='_blank' rel="noreferrer"><i class="uil uil-github"></i></a>
        <a href='mailto:beverly.diaz@hotmail.com' className='home__social-icon'><i class="uil uil-envelope-alt"></i></a>
    </div>
  )
}

export default Social
