import work1 from '../../assets/work1.jpg';
import work2 from '../../assets/work2.jpg';
import work3 from  '../../assets/work3.jpg';


export const projectsData = [
    {
        id: 1,
        image: work1,
        title: 'Application mobile pour un café',
        description: "Vous souhaitez commander un café ? Découvrez l'application mobile pour un coffee shop.",
        skills: 'Flutter',
        category: 'Mobile',
        demo:'',
        github: <a href="https://github.com/BeverlyDiaz/Coffee_Shop_Mobile_App.git" className='work__button button button--flex' target='_blank' rel="noreferrer">Github</a>

    },
    {
        id: 2,
        image: work2,
        title: 'Quiz',
        description: "Réalisation d'un quiz culture générale en JS pour approfondir mes compétences.",
        skills: 'Java Script',
        category: 'Web',
        demo: <a href='https://beverlydiaz.github.io/Quizz/' className='work__button button button--flex' target='_blank' rel="noreferrer">Demo</a>,
        github: <a href="https://github.com/BeverlyDiaz/Quizz.git" className='work__button button button--flex' target='_blank' rel="noreferrer">Github</a>

    },
    {
        id: 3,
        image: work3,
        title: 'Pokewiki',
        description: "Projet wikipédia version pokémon réalisé en java Script avec l'utilisation de l'API Pokebuild.",
        skills: 'Java Script',
        category: 'Web',
        demo: '',
        github: <a href="https://github.com/BeverlyDiaz/Pokedex_Version_1" className='work__button button button--flex' target='_blank' rel="noreferrer">Github</a>


    }
]

export const projectsNav = [
    {
        name: 'Tout'
    },
    {
        name: 'Mobile'
    },
    {
        name: 'Web'
    }
]