import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const WorksItems = ({ item }) => {
  useEffect(() => {
    AOS.init();
  }, [])
const [active, setActive] = useState(false);

function closeItem() {
  setActive(true);
}

function restoreHover() {
  setActive(false); 
}

  return (
    <div className='work__card' key={item.id} onTouchStart={restoreHover} data-aos='fade-up' data-aos-duration="2000">
      <img src={item.image} alt='' className='work__img' />
      <div className={`work__mask ${active ? "close" : ""}`}>
        <i className="uil uil-times" onClick={closeItem}></i>
        <h3 className='work__title'>{item.title}</h3>
        <p>{item.description}</p>
        <ul className='work_skills'>
          <li>{item.skills}</li>
        </ul>
        <div className='demo-github__button'>
          {item.demo}
          {item.github}
        </div>
      </div>
    </div>
  );
};

export default WorksItems;
