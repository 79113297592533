import React from 'react'

const Info = () => {
  return (
    <div className='about__info grid'>
        <div className="about__box" data-aos="zoom-in" data-aos-duration="2000">
        <i class="uil uil-map-marker about__icon"></i>
        <h3 className="about__title">Le Mans, France</h3>
        </div>
        <div className="about__box" data-aos="zoom-in" data-aos-duration="2000">
        <i class="uil uil-book about__icon"></i>
        <h3 className="about__title">Autodidacte</h3>
        </div>
      
    </div>
  )
}

export default Info
