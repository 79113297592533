import Header from "./components/header/header";
import './App.css';
import Home from "./components/home/Home";
import About from "./components/about/about";
import Skills from "./components/skills/skills";
import Contact from "./components/contact/contact";
import Project from "./components/project/project";
import AnimatedCursor from "react-animated-cursor";



function App() {
  return (
    <div className="App">
      <AnimatedCursor
     innerSize={8}
     outerSize={35}
     innerScale={1}
     outerScale={2}
     outerAlpha={0}
     hasBlendMode={true}
     innerStyle={{
       backgroundColor: 'var(--cursor-color)'
     }}
     outerStyle={{
       backgroundColor: 'var(--cursor-color)'
     }} />
     <Header />
     <main className="main">
      <Home/>
      <About/>
      <Skills/>
      <Project/>
      <Contact/>
     </main>
    </div>
  );
}

export default App;