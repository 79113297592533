import React, { useState } from "react";
import "./header.css";
import DarkMode from './../darkMode/DarkMode'

const Header = () => {
  const [navOpen, setNavOpen] = useState(false);
  return (
    <div className="nav" id="home">
      <div className="nav__container">
        <div className="navbar">
          <DarkMode />
          <div className="menu__toggle" onClick={() => setNavOpen(!navOpen)}>
            <div className={navOpen ? "hamBox hamBoxOpen" : "hamBox"}>
              <span className={navOpen ? "lineTop spin" : "lineTop"}></span>
              <span className={navOpen ? "lineMiddle spin" : "lineMiddle"}></span>
              <span className={navOpen ? "lineBottom spin" : "lineBottom"}></span>
            </div>
          </div>
        </div>
        <div className="nav__overlay" style={
          {
            top: navOpen ? "0" : "-100%",
            transitionDelay: navOpen ? "0s" : "0s"
          }
        }
        >
          <ul className="nav__links">
            <li className="nav__item">
              <a href="#home" 
              onClick={() => setNavOpen(!navOpen)}
              style={{
                top: navOpen ? "0" : "120px",
                transitionDelay: navOpen ? "0.8s" : "0s"
              }}
              >Accueil</a>
              <div className="nav__item-wrapper"></div>
            </li>
            <li className="nav__item">
              <a href="#about" onClick={() => setNavOpen(!navOpen)}
              style={{
                top: navOpen ? "0" : "120px",
                transitionDelay: navOpen ? "0.9s" : "0s"
              }}>A propos</a>
              <div className="nav__item-wrapper"></div>
            </li>
            <li className="nav__item">
              <a href="#skills" onClick={() => setNavOpen(!navOpen)}
              style={{
                top: navOpen ? "0" : "120px",
                transitionDelay: navOpen ? "1s" : "0s"
              }}>Compétences</a>
              <div className="nav__item-wrapper"></div>
            </li>
            <li className="nav__item">
              <a href="#project" onClick={() => setNavOpen(!navOpen)}
              style={{
                top: navOpen ? "0" : "120px",
                transitionDelay: navOpen ? "1.1s" : "0s"
              }}>Projets</a>
              <div className="nav__item-wrapper"></div>
            </li>
            <li className="nav__item">
              <a href="#contact" onClick={() => setNavOpen(!navOpen)}
              style={{
                top: navOpen ? "0" : "120px",
                transitionDelay: navOpen ? "1.2s" : "0s"
              }}>Contact</a>
              <div className="nav__item-wrapper"></div>
            </li>
          </ul>
          <div className="nav__footer">
            <div className="social__media">
              <ul>
                <li>
                  <a href="https://fr.linkedin.com/in/beverly-diaz"
                  target='_blank' rel="noreferrer"
                  style={{
                    bottom: navOpen ? "0" : "-20px",
                    opacity: navOpen ? "1" : "0",
                    transitionDelay: navOpen ? "1.2s" : "0",
                  }}><i class="uil uil-linkedin"></i></a>
                </li>
                <li>
                  <a href="https://github.com/BeverlyDiaz" 
                  target='_blank' rel="noreferrer"
                   style={{
                    bottom: navOpen ? "0" : "-20px",
                    opacity: navOpen ? "1" : "0",
                    transitionDelay: navOpen ? "1.3s" : "0",
                  }}><i class="uil uil-github"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
