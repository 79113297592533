import React from 'react'

const SkillsData = () => {
  return (
    <div className="skills__content">
    <div className="skills__box">
      <ul className="skills__list">
        <li className="skills__item">
          <div className="skills__data">
            <i className="uil uil-html5 icone"></i>
            <h3 className="skills__name">HTML</h3>
          </div>
        </li>
        <li className="skills__item">
          <div className="skills__data">
            <i className="uil uil-css3-simple icone"></i>
            <h3 className="skills__name">CSS</h3>
          </div>
        </li>
        <li className="skills__item">
          <div className="skills__data">
          <i class='bx bxl-sass icone'></i>
            <h3 className="skills__name">SASS</h3>
          </div>
        </li>
        <li className="skills__item">
          <div className="skills__data">
          <i class="uil uil-java-script icone"></i>
            <h3 className="skills__name">Java Script</h3>
          </div>
        </li>
        <li className="skills__item">
          <div className="skills__data">
          <i class="uil uil-react icone"></i>
            <h3 className="skills__name">React JS</h3>
          </div>
        </li>
        <li className="skills__item">
          <div className="skills__data">
          <i class='bx bxl-php icone' ></i>
            <h3 className="skills__name">PHP</h3>
          </div>
        </li>
        <li className="skills__item">
          <div className="skills__data">
            <i className="uil uil-css3-simple icone"></i>
            <h3 className="skills__name">MySQL</h3>
          </div>
        </li>
        <li className="skills__item">
          <div className="skills__data">
          <i class='bx bxl-firebase icone' ></i>
            <h3 className="skills__name">Firebase</h3>
          </div>
        </li>
        <li className="skills__item">
          <div className="skills__data">
          <i class='bx bxl-flutter icone' ></i>
            <h3 className="skills__name">Flutter</h3>
          </div>
        </li>
        
      </ul>
    </div>
  </div>
  )
}

export default SkillsData
