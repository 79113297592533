
import React, { useEffect } from "react";
import "./about.css";
import AboutImg from "../../assets/About.png";
import Info from "./info";
import AOS from 'aos';
import 'aos/dist/aos.css';

const About = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className="about section" id="about">
      <div data-aos='fade-right' data-aos-duration="2000">
        <h2 className="section__title">A propos</h2>
      </div>
      <div className="about__container container grid">
        <div data-aos="zoom-in" data-aos-duration="2000">
          <img src={AboutImg} className="about__img" alt="Beverly" />
        </div>
        <div className="about__data">
          <Info />
          <div data-aos="fade-left" data-aos-duration="2000">
            <p className="about__description">
              Je suis étudiante en deuxième année de la formation Web Concepteur en
              alternance à EKOD situé au Mans. Je souhaite poursuivre en septembre
              2024 avec un bachelor développeur d'application web et mobile.
              Mon objectif est de pouvoir améliorer mes compétences dans le
              développement Web front et Back-End.<br /> Je souhaite appronfondir mes
              connaissances en React JS, flutter et apprendre de nouveaux langages,
              frameworks.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
