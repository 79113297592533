import React, { useEffect } from "react";
import './project.css';
import Works from './works';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Project = () => {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <section className='work section'>
      <div data-aos='fade-right' data-aos-duration="2000">
        <h2 className='section__title' id='project'>Mes projets</h2>
      </div>
      <Works />
    </section>
  )
}

export default Project
