import React,{useEffect} from "react";
import './skills.css';
import SkillsData from './skillsData';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Skills = () => {
  useEffect(() => {
    AOS.init();
  }, [])

  
  return (
    <section className='skills section' id='skills'>
      <div data-aos='fade-left' data-aos-duration="2000">
      <h2 className="section__title">Mes compétences</h2>
      </div>
   <div className="skills__container container grid" data-aos='fade-up' data-aos-duration="2000">
    <SkillsData/>
   </div>
    </section>
  )
}

export default Skills
